import { FC, useCallback } from 'react';
import { m } from 'framer-motion';
import cn from 'classnames';
import { LENS_INFO_TABS } from '@utils/constants/info-module';
import { useBFContext } from '@context';
import { RX_TYPE } from '@utils/constants/base-skus';
import { buildFlowLensContainerVariants } from '@utils/motions';
import styles from '../PrescriptionStep/PrescriptionStep.module.scss';
import Lens from '../Lens/Lens';

const Lenses: FC = () => {
	const { rxType, rxOptions } = useBFContext();
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const currentLensOptions = rxOptions.find(option => option.optionValue === rxType).lenses;

	const getEducationTabs = useCallback(() => {
		const tabs = LENS_INFO_TABS;

		return currentLensOptions.map(option => {
			// TODO: this is a hacky way to match the tabs to the lens options, add some kind of translator
			return tabs.find(tab => tab.title.includes(option.name));
		});
	}, [currentLensOptions]);

	return (
		<m.ul
			initial='hidden'
			animate='show'
			variants={buildFlowLensContainerVariants}
			className={cn({
				[styles['lenses-list']]: true,
				[styles['lenses-list-w-readers']]: isReadersSelected,
			})}
		>
			{currentLensOptions.map((lens, index) => (
				<Lens key={`${lens.optionValue}-lens-option`} lens={lens} index={index} getEducationTabs={getEducationTabs} />
			))}
		</m.ul>
	);
};

export default Lenses;
